@import '../../common.scss';

.setting__modal {
  width: 320px;
  padding: 24px;
  position: relative;

  @media screen and (max-width: 768px) {
    width: 300px;

    .hide__option--isMobile {
      display: none !important;
    }
  }

  .setting__title {
    margin-bottom: 24px;
    font-size: 20px;
  }

  .setting__modal--close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 16px;
  }

  .setting__option--wrap {
    display: flex;
    flex-direction: column;
    .option__item {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      font-size: 16px;
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }

      &:disabled {
        color: $gray500;
      }
    }
  }
}
