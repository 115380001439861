$gray100: #FAFAFA;
$gray200: #F5F5F5;
$gray300: #E8E8E8;
$gray400: #E0E0E0;
$gray500: #C0C0C0;
$gray550: #ABABAB;
$gray600: #959595;
$gray700: #707070;
$gray800: #5c5c5c;
$gray900: #333333;

$blue200: #94DCFF;
$blue300: #00ABFF;
$blue400: #1A8EF9;

$red200: #FFCDD2;
$red300: #FF707D;
$red400: #fd5354;

