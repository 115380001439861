@import '../../common.scss';

.reset__modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .reset__title {
    font-weight: 500;
    font-size: 18px;
  }

  .reset__title--sub {
    margin: 24px 0;
    font-size: 14px;
    color: $gray800;
  }

  .reset__form {
    width: 100%;

    .form__input--wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 400px;
      margin-bottom: 24px;

      .form__label {
        width: 72px;
        margin-right: 24px;
        font-size: 14px;
        color: $gray800;
      }

      .form__id-input,
      .form__email-input  {
        width: 240px;
        height: 40px;
        padding: 0px 12px;
        background-color: white;
        border: 1px solid $gray400;
        border-radius: 4px;
      }

      .form--error-status-message {
        position: absolute;
        top: 48px;
        font-size: 12px;
        color: $red400;
      }
    }

    .form__submit-wrap {
      display: flex;
      justify-content: center;
      width: 100%;

      .cancel__button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        width: 100px;
        height: 40px;
        border-radius: 4px;
        background-color: $gray300;
        color: $gray800;
        font-weight: 500;
      }

      .submit__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 40px;
        border-radius: 4px;
        background-color: #00ABFF;
        color: white;
        font-weight: 500;
      }
    }
  }
}