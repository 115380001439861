@import '../../common.scss';

.enter-exit-manage__modal {
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  width: 505px;
  padding: 30px 60px;
  box-sizing: border-box;

  .enter-exit-manage__title {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -1%;
  }

  .enter-exit-manage__toggle {
    color: $gray700;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -1%;

    &.active {
      color: $blue300;
    }
  }

  .enter-exit-manage__description {
    > p {
      display: flex;
      align-items: center;
      color: $gray700;
      font-size: 16px;
      line-height: 25.6px;
      letter-spacing: -0.01em;

      &::before {
        display: flex;
        content: ' ';
        width: 3px;
        height: 3px;
        margin: 0 5px 0 0;
        border-radius: 100%;
        background: $gray700;
      }
    }
  }
}
