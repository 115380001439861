@import '../../common.scss';

.login__page {
  width: 100%;
  height: 100%;
  display: flex;

  .left__section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 0;
    width: 69%;
    background-color: #30bbff;

    @media screen and (max-width: 480px) {
      display: none;
    }

    .title-area {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .title-area__logo {
        width: 36px;
        margin-bottom: 20px;
      }

      .title-area__title {
        color: white;
        line-height: 1.29;
        font-weight: 500;
        font-size: 32px;
      }
    }

    .example-image {
      width: 100%;
      max-width: 1092px;
      margin-top: 100px;
      object-fit: contain;
      @media screen and (max-width: 1280px) {
        margin-top: 76px;
        max-width: 730px;
      }
    }
  }

  .right__section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 39%;
    min-width: 398px;
    padding: 50px 0;
    .login-form__wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1 1 auto;
      width: 240px;
    
      .login-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        .login-form__title {
          margin-bottom: 32px;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: -0.015em;
          text-align: center;
          color: $gray900;
        }
  
        .login-form__input-wrap {
          display: flex;
          flex-direction: column;
          position: relative;
          width: 100%;

          .login-form__input {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            padding: 0 12px;
            width: 100%;
            height: 40px;
            padding: 0px 12px;
            background-color: white;
            border: 1px solid $gray400;
            border-radius: 4px;

            &:disabled {
              border-color: $red400;
            }
          }
  
          .login-form__input--error-status-message {
            position: absolute;
            top: 48px;
            font-size: 12px;
            color: $red400;
          }
        }

        .login-form__submit {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 12px 0 16px 0;
          width: 100%;
          height: 40px;
          border-radius: 4px;
          background-color: #00ABFF;
          color: white;
          font-weight: 500;
        }
      }
      
      .login-form__find-password-link {
        margin: 16px 0;
        font-size: 14px;
        color: $gray700;
        text-decoration: underline;
        text-underline-position: under;
        text-decoration-color: $gray700;
      }
    }

    .customer-guide-address {
      display: flex;
      justify-content: center;
      margin-bottom: 56px;
      width: 100%;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.002em;
      color: $gray700;

      .customer-guide-address__link {
        display: flex;
        align-items: center;
        color: $gray700;
        .customer-guide__icon {
          margin-right: 4px;
        }

        &.phone-link {
          margin-right: 16px;
        }

        &.kakao-link {
          margin-left: 16px;
        }
      }      
    }
  }
}