@import '../../common.scss';

.attendance__input--wrap {
  display: flex;
  flex-direction: column;
  width: 39%;

  &.full {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (orientation: portrait) {
    width: 100%;
  }


  .attendance__header {
    display: flex;
    height: 40px;
    padding: 20px;
    
    .header__config {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      color:  $gray600;
      svg {
        margin-right: 4px;
        path {
          fill: $gray600;
        }
      }
    }

    .header__time {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
    }

    .header__refresh-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      .refresh__circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $gray500;
         svg > path {
           fill: $gray600;
         }
      }
    }
  }

  .attendance__body {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;

    .academy__logo {
      height: 60px;
    }

    .inserted-numeric__list {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 36px 0;
      
      .inserted-numeric__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        min-height: 64px;
        border-radius: 50%;
        background-color: $gray200;
        font-size: 40px;
        &:not(:last-of-type) {
          margin-right: 20px;
        }
      }
      
    }

    .numeric__button-list {
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      max-width: 700px;
      height: 100%;

      .numeric__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33%;
        height: 18%;
        box-sizing: border-box;
        font-weight: 500;
        font-size: 40px;

        @media screen and (max-width: 768px) {
          font-size: 32px;
        }
      }
    }
  }

  .attendance__submit-button {
    height: 80px;
    background-color: $blue300;
    font-size: 36px;
    font-weight: 500;
    color: white;
    &:disabled {
      background-color: $gray400;
    }
  }
}