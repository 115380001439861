@import '../../common.scss';

.reset__notice--wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;

  .check__circle {
    // width: 40px;
    margin-bottom: 10px;
    transform: scale('');
  }

  .notice__title {
    font-size: 20px;
  }

  .notice__sub-text {
    margin: 24px 0 ;
    font-size: 14px;
    color: $gray600;
  }

  .confirm__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 40px;
    border-radius: 4px;
    color: white;
    background-color: $blue300;
  }
}