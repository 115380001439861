html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  user-select: none;
}

html {
    height: 100%;
}

body {
  overflow: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
li,
ol {
  list-style: none;
}

[type='text'] {
  padding: 0;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  font-size: 14px;
  background: transparent;
  cursor: pointer;
  color: black; 
}

a {
  color: inherit;
  text-decoration: none;
}

button:disabled{
  cursor: default;
}

svg {
  display: block;
}
input {
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  outline: none;
}

textarea:focus {
  outline: none;
}

img {
  image-rendering: -webkit-optimize-contrast;
}

body, .app {
  width: 100%;
  height: 100%;
}
