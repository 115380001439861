@import '../../common.scss';

.detailed-student-list__page {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;

  .student__grade {
    width: 70px;
  }

  .student__name {
    width: 120px;
  }

  .student__phone {
    width: 200px;
  }

  .login__id {
    width: 150px;
  }

  .student__attendanceCode {
    width: 100px;
  }

  @media screen and (max-width: 768px) {
    .student__grade {
      width: 40px;
    }
  
    .student__name {
      width: 60px;
    }
  
    .student__phone {
      width: 100px;
    }
  
    .login__id {
      width: 75px;
    }
  
    .student__attendanceCode {
      width: 50px;
    }
  }

  .student-list__header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    box-sizing: border-box;
    border-bottom: 2px solid $gray300;

    .student__grade,
    .student__name, 
    .student__phone, 
    .login__id,
    .student__attendanceCode {
      display: flex;
      justify-content: center;
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      &:not(:last-of-type) {
        margin-right: 40px;
      }
    }
  }

  .student-list__table {
    height: calc(100% - 80px) !important;
    > div {
      margin-bottom: 60px !important;
    }

    .table__row {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;

      .student__grade,
      .student__name, 
      .student__phone, 
      .login__id, 
      .student__attendanceCode {
        font-size: 20px;
        color: $gray600;
        text-align: center;
        &:not(:last-of-type) {
          margin-right: 40px;
        }
      }
    }
  }

  .close__button {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: $blue300;
    color: white;
    font-size: 24px;
    font-weight: 500;
  }
}