@import '../../common.scss';

.student__list--wrap {
  width: 61%;
  background-color: white;

  @media screen and (max-width: 768px) {
    display: none;
  }

  @media screen and (orientation: portrait) {
    display: none;
  }


  .student__name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 140px;
    padding-left: 24px;
    .student__dot {
      width: 16px;
      min-width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: $gray300;
      margin-right: 20px;

      &.student_attendance--enter {
        background-color: $blue300; 
      }
      &.student_attendance--quit {
        background-color: $red400;        
      }
    }
  }
  
  .enter__history {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
  }

  .exit__history {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
  }

  .student__header {
    display: flex;
    height: 80px;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 2px solid $gray400;
    box-sizing: border-box;
    .student__name {
      display: flex;
      justify-content: center;
    }

    .enter__history {
      color: $blue300;
    }

    .exit__history {
      color: $red300;
    }
  }    

  .student__table {
    flex: 1;
    height: calc(100% - 80px) !important;
    box-sizing: border-box;

    .table__row {
      display: flex;
      width: 100%;
      height: 60px;
      border-bottom: 1px solid $gray400;
    }
  }
}