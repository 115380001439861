@import '../../common.scss';

.confirm__modal {
  display: flex;
  flex-direction: column;
  width: 320px;
  padding: 24px;

  .guide__text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    line-height: 1.29;
  }

  .confirm__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .form__input {
      width: 240px;
      height: 40px;
      padding: 0px 12px;
      background-color: white;
      border-radius: 4px;
      border: 1px solid rgb(224, 224, 224);
      border-radius: 4px;
    }

    .form__button--wrap {
      display: flex;
      justify-content: space-between;
      width: 240px;
      margin-top: 24px;
    
      > button {
        width: 100px;
        height: 40px;
        border-radius: 4px;
        font-weight: 500;
      }

      .form__button--reset {
        background-color: #E0E0E0;
      }

      .form__button--submit {
        background-color: #00ABFF;
        color: white;
      }
    }
  }

}