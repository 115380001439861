@import '../../common.scss';

.toggle {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  & ~ .toggle-label-default {
    position: relative;
    width: 40px;
    height: 20px;
    border: 1px solid $gray400;
    border-radius: 16px;
    background-color: $gray100;
    cursor: pointer;

    &::after {
      position: absolute;
      width: 20px;
      height: 20px;
      content: '';
      border-radius: 50%;
      background-color: $gray500;
      transform: translateX(0);
      transition: all 0.12s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }
  }

  &:checked ~ .toggle-label-default::after {
    background-color: $blue300;
    transform: translateX(100%);
    transition: all 0.12s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  &:checked ~ .toggle-label {
    color: $blue300;
  }
}

.toggle-label {
  cursor: pointer;
  color: $gray800;
  margin-left: 8px;
}

.toggle-container {
  display: inline-flex;
  align-items: center;
}
