@import '../../common.scss';

.warning__modal-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  padding: 20px;
  .check__button {
    margin-top: 20px;
    width: 100px;
    height: 40px;
    border-radius: 4px; 
    background-color: $blue300;
    color: white;
  }
}